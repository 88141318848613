<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      />
      <b10-fab-button
        v-if="presupuestoPendiente && hasEditPerm(permissions.presupuestoCuota.id)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './PresupuestoCuotaViewData'
import { PRESUPUESTOCLI } from '@/utils/consts'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        'descripcion_cuota',
        'inf_comercial',
        'periodo_descripcion',
        { name: 'precio_periodo', filter: this.$options.filters.currency },
        { name: 'precio_mes', filter: this.$options.filters.currency },
        { name: 'dto', filter: this.$options.filters.percent },
        'unidades',
        { name: 'precio_total', filter: this.$options.filters.currency },
        { name: 'opcional', filter: this.$options.filters.humanizeBoolean },
        { name: 'activacion_automatica', filter: this.$options.filters.humanizeBoolean },
        'meses_permanencia',
      ],
      showingDialogs: {
        moreInfo: false,
      },
      presupuestocli: {},
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idpresupuesto_cuota
        item.title = this.$online.presupuestoCuota.title(item)
        item.subtitle = this.$online.presupuestoCuota.subtitle(item)
        item.badge = this.$options.filters.currency(item.precio_total)
        item.badgeColor = 'info'
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
    presupuestoPendiente () {
      return (
        this.presupuestocli &&
        PRESUPUESTOCLI.clasificacionEstadosPendientes.includes(this.presupuestocli.idclasificacion_estado)
      )
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.$online.presupuestoCuota.title(this.item.dataset)
      const resp = await Data.selectLoadPage(this, this.routeParams.idpresupuestocli)
      this.presupuestocli = resp.data.selectPresupuestocli.result.dataset[0]
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectPresupuestoCuota(
          this, this.routeParams.idpresupuesto_cuota
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickEdit () {
      this.$appRouter.push({
        name: 'presupuestos__cuotas__presupuestocuota-edit',
        params: {
          idpresupuestocli: this.routeParams.idpresupuestocli,
          idpresupuesto_cuota: this.routeParams.idpresupuesto_cuota,
        },
      })
    },
  },
}
</script>
